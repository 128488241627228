import { Auth, authLoginHandler, AuthUser } from '@kc/konecranes-auth-js'

import config from '../../config'

import { createAuth0InstanceMock } from './auth.mock'

let auth

const AUTH_CONFIG = ({
  local: require('./variables/auth0-local.json'),
  dev: require('./variables/auth0-dev.json'),
  qa: require('./variables/auth0-qa.json'),
  pilot: require('./variables/auth0-pilot.json'),
  prod: require('./variables/auth0-prod.json'),
}[config.stage])

const createAuth0Instance = () => {
  if (config.stage === 'mock') {
    return createAuth0InstanceMock()
  }

  if (config.stage && !auth)  {
    auth =  new Auth(AUTH_CONFIG)
  }

  return auth
}

const login = (cb) => {
  createAuth0Instance().isAuthenticated(cb)
}

const handleAuthentication = (location) => {
  authLoginHandler(createAuth0Instance(), location)
}

const isAutheticatedInSso = () => {
  return !!createAuth0Instance().getIdTokenFromSessionStore()
}

const logout = () => {
  createAuth0Instance().ssoLogout()
  auth = undefined
}

const getAccessToken = () => {
  return createAuth0Instance().getAccessToken()
}

const getProfile = () => {
  const auth = createAuth0Instance()
  return auth && auth.id_token_expires
    ? new AuthUser(auth)
    : {}
}

const getLanguage = () => {
  return getProfile().language || 'en'
}

const getCidmRole = () => {
  return getProfile().role
}

const changeProfile = (patch, cb) => {
  createAuth0Instance().silentLogin(cb, patch)
}

export default {
  getAccessToken,
  handleAuthentication,
  login,
  isAutheticatedInSso,
  logout,
  getLanguage,
  getCidmRole,
  getProfile,
  changeProfile,
}
