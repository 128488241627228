import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
const buildEnv = process.env.REACT_APP_STAGE || 'local'
const apiConfig = JSON.parse(
  process.env[`REACT_APP_API_CONFIG_${buildEnv.toUpperCase()}`] || '{}'
)
const routerKeys = {
  dev: '1739E25A-6BBF-48F7-BE54-4938DD6CCAD9',
  qa: 'D0CC1FF8-E0CD-4333-9C7B-188C60D46364',
  pilot: 'AD30E879-83A3-4423-8D90-D2C92AC7D69F',
  prod: 'EC4BAD51-F77F-4BCE-8E39-D6B4DED40E0F',
}

const cache = new InMemoryCache({
  typePolicies: {
    Asset: {
      keyFields: ['assetId'],
      fields: {
        truConnect: {
          merge: true,
        },
      },
    },
    Facet: {
      keyFields: false,
    },
    TrendPeriod: {
      keyFields: false,
    },
    EventPeriod: {
      keyFields: false,
    },
    MostFrequentEvent: {
      keyFields: false,
    },
  },
})

const link = new HttpLink({
  uri: apiConfig.assetFleetApi,
  fetchOptions: {
    mode: 'cors',
  },
  fetch: async (uri, options) => {
    // Call function which collects token from sessionStorage
    return fetch(uri, {
      ...options,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        'Content-Type': 'application/json',
        'x-router-key': routerKeys[buildEnv],
      },
    })
  },
})

// Reload on every fetch
export default new ApolloClient({
  connectToDevTools: true,
  cache,
  link,
})
